import FlexibleData from '@lib/cms/flexibleData';
import GQL from '@lib/cms/gql';
import FlexiblePage from '@templates/FlexiblePage';

export async function getStaticPaths() {
  const { data } = await GQL.fetch(`
    query {
      flexPageCollection {
        items {
          slug
        }
      }
    }
  `);

  const EXCLUDED_SLUGS = ['communities', 'blog-landing', 'home', 'schools', 'register'];

  const slugs = data.flexPageCollection.items
    .filter((item) => {
      if (EXCLUDED_SLUGS.includes(item.slug)) return false;
      return true;
    })
    .map((item) => {
      return {
        params: { slug: item.slug }
      };
    });

  return {
    fallback: false,
    paths: slugs
  };
}

export async function getStaticProps(context) {
  const slug = context.params.slug;
  const preview = context.preview || false;
  const previewEnv = context?.previewData?.environment ?? null;

  const data = (await FlexibleData.fetch(slug, preview, previewEnv)) || [];

  let specializedPageSchema = null;
  if (slug === 'contact') {
    specializedPageSchema = 'contactSchema';
  } else if (slug === 'about') {
    specializedPageSchema = 'aboutSchema';
  }

  return {
    props: { data, preview, specializedPageSchema }
  };
}

const FlexPage = ({ data, preview, specializedPageSchema }) => {
  return (
    <FlexiblePage data={data} preview={preview} specializedPageSchema={specializedPageSchema} />
  );
};

export default FlexPage;
