import RenderBlocks from '@lib/cms/renderBlocks';

import Layout from '@layout/index';

const FlexiblePage = ({ children, data, preview, specializedPageSchema }) => {
  return (
    <Layout
      preview={preview}
      meta={{
        description: data?.metaDescription,
        image: data?.metaImage,
        title: data?.metaTitle
      }}
      specializedPageSchema={specializedPageSchema}
    >
      {data?.blocksCollection && RenderBlocks(data.blocksCollection.items)}
      {children}
    </Layout>
  );
};

export default FlexiblePage;
